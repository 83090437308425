<script setup>
  import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
  import { notify } from 'notiwind'
  import { Form } from 'vee-validate'
  import * as yup from 'yup'
  import 'yup-phone-lite'
  import { handleError } from '@/utils/error'
  import signupIdentitySchema from '~/utils/schema/signup-identity-schema'
  import cguSchema from '~/utils/schema/cgu-schema'
  import coverLetterSchema from '~/utils/schema/cover-letter-schema'

  const props = defineProps({
    careerSite: { required: true, type: Object },
    job: { type: Object },
    mandatoryLetter: { type: Boolean, default: false },
    canLetterQuestions: { type: Boolean, default: true },
    mobility: { default: false, type: Boolean },
    open: { default: false, type: Boolean },
    title: { required: true, type: String }
  })

  const emit = defineEmits(['close', 'submit'])

  const client = useStrapiClient()
  const { query } = useRoute()

  const coverLetter = ref(null)
  const open = ref(props.open)
  watch(
    () => props.open,
    value => (open.value = value)
  )

  const colors = reactive({
    primary: props.careerSite.primaryColor,
    text: props.careerSite.textColor
  })

  const schema = yup
    .object({
      cv: yupFile({ types: ['application/pdf'] })
        .required('${label} requis')
        .label('CV'),
      cgu: cguSchema()
    })
    .concat(signupIdentitySchema())
    .concat(coverLetterSchema(props.mandatoryLetter, props.canLetterQuestions))

  const submitting = ref(false)

  async function onSubmit(values, actions) {
    if (submitting.value) return
    submitting.value = true
    try {
      values = schema.cast(values)
      if (!values.letter && coverLetter.value?.canGenerateLetter()) {
        const pdf = await coverLetter.value.getGeneratedLetterPdf()
        values.letter = pdf
      }
      if (!values.official) delete values.exam

      const formData = new FormData()
      formData.append('files.cv', values.cv)
      delete values.cv
      if (values.letter) {
        formData.append('files.letter', values.letter)
        delete values.letter
      }

      const data = values

      if (query.o) {
        data.origin =
          {
            ap: 'apec',
            et: 'emploi-territorial',
            go: 'google',
            in: 'indeed',
            talent: 'talent',
            pp: 'pp',
            ro: 'robopost'
          }[query.o] ?? null
      }
      console.log('origin:', data.origin)
      data.originUrl = sessionStorage.getItem(`job:${props.job?.id}`)

      data.mobility = !!props.mobility
      formData.append('data', JSON.stringify(data))
      const endpoint = `career-sites/${props.careerSite.id}/apply${props.job ? `/${props.job.id}` : ''}`
      await client(endpoint, { method: 'POST', body: formData })
      open.value = false
      emit('close')
      actions.resetForm()
      emit('submit')
      notify({ group: 'main', type: 'success', title: 'Votre candidature a bien été envoyée' })
    } catch (error) {
      console.error(error)
      if (error.error?.status === 403) notify({ group: 'main', type: 'danger', title: error.error.message })
      else handleError(error, true)
    } finally {
      submitting.value = false
    }
  }
</script>

<template>
  <Dialog
    class="fixed inset-0 z-20 flex h-full w-full justify-center overflow-auto bg-black bg-opacity-50 p-4 font-main backdrop-blur"
    id="dialog"
    :open="open"
    :style="{ color: colors.text }"
  >
    <div class="my-auto py-2">
      <DialogPanel class="w-full max-w-lg rounded-xl bg-white p-4 shadow">
        <DialogTitle class="text-center font-special text-3xl font-bold" :style="{ color: colors.primary }">{{ title }}</DialogTitle>
        <Form class="mt-4" v-slot="{ errors, values }" :initialValues="{ cgu: false }" :validation-schema="schema" @submit="onSubmit">
          <div class="-m-2 flex flex-wrap">
            <FormSignupIdentityFields :errors="errors" :values="values" />
            <FormField class="basis-full p-2" id="cv" accept="application/pdf" :error="errors.cv" label="CV" name="cv" required type="file" />
            <div class="basis-full p-2" v-if="!!props.job">
              <FormCoverLetter ref="coverLetter" :job="job" :errors="errors" :values="values" :required="props.mandatoryLetter" />
            </div>
            <FormCguCheckbox class="p-2" :error="errors.cgu" />
          </div>
          <div class="mt-4 space-x-2 text-center">
            <Button color="secondary" outline type="reset" :style="{ color: colors.text }" @click="$emit('close')">Annuler</Button>
            <Button :disabled="submitting.value" :style="{ borderColor: colors.primary, color: colors.text }" type="submit">Postuler</Button>
          </div>
        </Form>
      </DialogPanel>
    </div>
  </Dialog>
</template>
